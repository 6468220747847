import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SettingsService } from './settings.service'
import { HelperService } from './helper.service';
import * as _ from 'lodash';
import { ConstService } from './const.service';
import moment from 'moment';
import { Backlog } from 'src/app/containers/maintenance-management/create-backlog/create-backlog.component';
import { CryptoService } from './crypto.service';
import { RecurrenceModel } from 'src/app/containers/maintenance-management/outage-calendar/create-edit-outage-window/create-edit-outage-window.component';
import { TimezoneConversionService } from './timezone.service';
import { ApplicationService } from './application.service';
@Injectable({
  providedIn: 'root'
})
export class ManageMaintenanceService {

  private API: string;

  constructor
  (
    private http: HttpClient,
    mysettingsService: SettingsService,
    private helper: HelperService,
    private constService: ConstService,
    private cryptoService: CryptoService,
    private timezoneConversionService: TimezoneConversionService,
    private appSvc: ApplicationService
  ) {
    this.cryptoService.getMPinAPI = () => this.getMPin();
    this.API = mysettingsService.settings.manageMaintenanceApiURL;
  }

  /**
  * Performs a request with `get` http method.
  * @param url the url
  * @param options the request options
  * @returns Observable<any>
  */
  get(url: string, options?: any): Observable<any> {
    return this.http
      .get(this.API + url, this.helper.requestOptions(options))
      .pipe(catchError(err => this.helper.catchAuthError(err)));
  }

  /**
  * Performs a request with `post` http method.
  * @param url the url
  * @data data to be sent in request body
  * @param options the request options
  * @returns Observable<any>
  */
  post(url: string, data?, options?: any, stopErrorHandler?: boolean): Observable<any> {
    return this.http
      .post(this.API + url, data, this.helper.requestOptions(options))
      .pipe(catchError(err => this.helper.catchAuthError(err)));
  }

  /**
  * Performs a request with `post` http method.
  * @param url the url
  * @data data to be sent in request body
  * @param options the request options
  * @returns Observable<any>
  */
  put(url: string, data?, options?: any): Observable<any> {
    return this.http
      .put(this.API + url, data, this.helper.requestOptions(options))
      .pipe(catchError(err => this.helper.catchAuthError(err)));
  }

  patch(url: string, data?, options?: any): Observable<any> {
    return this.http
      .patch(this.API + url, data, this.helper.requestOptions(options))
      .pipe(catchError(err => this.helper.catchAuthError(err)));
  }

  delete(url: string, options?: any): Observable<any> {
    return this.http
      .delete(this.API + url, this.helper.requestOptions(options))
      .pipe(catchError(err => this.helper.catchAuthError(err)));
  }

  getTaskFilterMasterData(type: 'Backlog' | 'Event' | 'History', filterId?:string): Observable<any> {
    const endpoint = type !== 'History' ? '/Common/GetFilterMasterData' : '/HistoryView/GetTaskHistoryMasterData';
    let requestUrl = `${endpoint}?mm_type=${type}`
    if (filterId) {
      requestUrl += `&filter_id=${filterId}`
    }
    return this.get(requestUrl)
      .pipe(map(res => {
        const filters: any = {};
        Object.keys(res?.filterMasterData).forEach(key => {
          filters[_.upperFirst(key)] = res.filterMasterData[key];
        });

        // transform the event list data
        if (filters.GetEventData) {
          filters.GetEventData = filters.GetEventData.map(eventDto => (Object.assign(eventDto, {
            eventName: eventDto.event,
            startDate: eventDto.eventStartDate,
            endDate: eventDto.eventEndDate,
          })));

          filters.GetEventData = _.sortBy(filters.GetEventData, 'eventName');
        }

        return filters;
      }));
  }

  getGroupOverview(filters: any, searchText: string, groupConfig: string[], mmType: 'Backlog' | 'Event' | 'History', isMyFlow = false) {
    return this.post(
      mmType !== 'History' ? `/Common/GetGroupOverview` : `/HistoryView/GetHistoryGroupOverview`,
      { groupConfig, filtersBacklogData: filters, searchText, mmType, isMyFlow }
    )
    .pipe(map(res => res?.getGroupOverviewInputDto))
    .pipe(map(overview => {
      const recursiveReplace = (group: any) => {
        if (!group.hasOwnProperty('tasksCount')) { return; }
        group.itemCount = group.tasksCount;
        delete group.tasksCount;
        (group.children || []).forEach(group => recursiveReplace(group));
      }
      recursiveReplace(overview);
      return overview;
    }));
  }

  getPaginatedTasks(filters: any, searchText: string, start: number, limit: number, mmType: 'Backlog' | 'Event' | 'History', isMyFlow = false) {
    return this.post(
      mmType !== 'History' ? `/common/GetPaginatedData` : `/HistoryView/GetPaginatedHistoryData`,
      { FiltersData: filters, searchText, StartLimit: start + 1, EndLimit: start + limit, mmType, isMyFlow }
    ).pipe(map(res => res.paginatedTasks));
  }


  getTasksForCart(filters: any, searchText: string, cart: any, mmType: 'Backlog' | 'Event' | 'History', isMyFlow = false) {
    // if there are no selected groups, there is no point making an API call
    if (!cart.selectedGroups.length) { return of([]); }

    return this.post(
      `/Events/AddToEventCart`,
      { FiltersData: filters, searchText, cart, installations: [], mmType, isMyFlow }
    ).pipe(map(res => {return res?.selectedTasks}));
  }

  getFilteredEvents() {
    return this.get('/Events/filter-events-outage-calendar')
      .pipe(tap(res => res.eventsData = res.eventsData && _.sortBy(res.eventsData, 'eventName')));
  }

  getOutageCalendarData(dateRange: { start: Date, end: Date }, eventId = this.constService.emptyGuid, isDefault:boolean): Observable<any> {
    let fetchTimeZoneObservable = of(null);
    if (!sessionStorage.getItem('projectTimeZone')) {
      fetchTimeZoneObservable = this.appSvc.GetDefaultCustomerDetails().pipe(tap((res) => {
        sessionStorage.setItem('projectTimeZone', res.returnObj.timeZone)
      }));
    }

    return fetchTimeZoneObservable.pipe(switchMap(() => {
      return this.get(`/OutageCalendar/GetOutageCalendarMasterData?eventId=` + eventId +
        '&startDate=' + moment(this.timezoneConversionService.readLocalDateAsProjectTimeZone(dateRange.start)).utc().format('YYYY-MM-DD HH:mm:ss')  +
        '&endDate=' + moment(this.timezoneConversionService.readLocalDateAsProjectTimeZone(dateRange.end)).utc().format('YYYY-MM-DD HH:mm:ss') +
        '&isDefault='+isDefault
        ).pipe(tap(res => {
          res.installations.forEach(installation => {
            installation.outageAreas.forEach(outageArea => {
              outageArea.outageWindows.forEach(window => {
                window.startDate = this.timezoneConversionService.readProjectDateAsLocalTimeZone(moment.utc(window.startDate).toDate());
                window.endDate = this.timezoneConversionService.readProjectDateAsLocalTimeZone(moment.utc(window.endDate).toDate());
              });
            });
          });
        }));
    }))

  }

  getTaskSelectionList() {
    return this.get('/Events/GetTaskSelectionList')
                .pipe(map(res=>res.taskSelectionResponse))
  }

  createOrUpdateEvent(payload:any) {
    delete payload.tasks
    if(payload.eventId){
      return this.put('/Events',payload)
    }
    else{
      return this.post('/Events/CreateEvent',payload)
    }
  }

  getBacklogDetails(backlogId: string): Observable<any> {
    return this.get(`/backlog/getBacklogDetails?backlogId=${backlogId}`).pipe(tap(res => {
      res.startDate = new Date(res.startDate);
      res.endDate = new Date(res.endDate);
    }));
  }

  getBacklogAssets(backlogId: string): Observable<any> {
    return this.get(`/backlog/backlog-asset-details?backlogId=${backlogId}`)
      .pipe(map(res => res.selectedAssets.map(asset => Object.assign({ id: asset.assetId, name: asset.sldEquipmentReference }, asset))));
  }

  getDueDatesForSavedAssetsInBacklog(backlogId: string): Observable<any> {
    return this.get(`/backlog/get-backlog-policy-due-date?backlogId=${backlogId}`)
      .pipe(map(res => res.backlogAssetPolicyNextDueDates));
  }

  saveBacklog(backlog: Backlog): Observable<any> {
    const payload = {
      backlogId: backlog.id,
      backlogName: backlog.name,
      duedateCriteria: backlog.dueDateCriteria,
      backlogStartDate: moment(backlog.startDate).format('YYYY-MM-DD'),
      backlogEndDate: moment(backlog.endDate).format('YYYY-MM-DD'),
      isProjectSlaSelected: false,
      installationIds: backlog.installations.map(installation => installation.id),
      selectedAssetsInfo: backlog.assets.map(asset => ({assetId: asset.id, isAsset: asset.isAsset})),
      assetPolicyDetails: backlog.policies.map(policy => ({
        assetId: policy.assetId,
        policyId: policy.maintActionId,
        nextDueDate: moment(policy.nextDueDate).format('YYYY-MM-DD'),
        isAsset: policy.isAsset,
        isResiduePolicy: policy.isResiduePolicy? true: false,
        taskCount: policy.taskCount
      }))
    };

    if (backlog.id) {
      return this.put('/backlog/updateBacklogInformation', payload);
    } else {
      return this.post('/backlog/createBacklogInformation', payload);
    }

  }

  //Assign Re-assign/ Schedule and Re-schedule API call
  getTeamsList(){
    return this.get('/TaskExecution/GetTeamsList');
  }

  saveTaskExecution(payload:any){
    return this.post('/TaskExecution/SaveTaskExecution',payload);
  }

  getTaskDetailsforTaskControl (taskId) {
    return this.get('/TaskExecution?taskId='+taskId).pipe(tap(res => {
      res.taskExecutionData.taskComments.forEach(comment => {
        comment.createDateTime = moment.utc(comment.createDateTime).local().toDate();
      });

      res.taskExecutionData.taskActivityLogs.forEach(activity => {
        activity.createDate = moment.utc(activity.createDate).local().toDate();
      });

      res.taskExecutionData.taskComments.sort((a, b) => moment(b.commentDate).diff(moment(a.commentDate)));
      res.backlogStartDate = new Date(res.backlogStartDate);
      res.backlogEndDate = new Date(res.backlogEndDate);
    }));
  }

  getExecutionTeamDetails(teamId: string) {
    return this.get(`/TaskExecution/GetTeamInfo?teamId=${teamId}`);
  }
  deleteBacklog(backlogId: string): Observable<any> {
    return this.delete(`/backlog/deletebacklog?backlogId=${backlogId}`);
  }

  getEventDetailsById(eventId){
    return this.get(`/Events?eventId=${eventId}`).pipe(tap(res => {
      res.startDate = new Date(res.startDate);
      res.endDate = new Date(res.endDate);
    }));
  }

  createOutageWindow(outageWindowRecurrences: { recurrence: RecurrenceModel, recurrenceTypeName: string, outageAreaId: string, outageWindows: any[]}[] ) {
   return this.post('/OutageCalendar/SaveOutageWindow', { OutageWindowRecurrenceDetails: outageWindowRecurrences });
  }

  getOutageWindowDetails(outageWindowId: string) {
    return this.get(`/OutageCalendar/GetOutageWindowDetails?outageWindowId=${outageWindowId}`)
      .pipe(map(res => res.outageWindowDetails));
  }

  editOutageWindow(payload: any) {
    return this.put(`/OutageCalendar/UpdateOutageWindow`,payload)
  }

  deleteOutageWindow(outageWindowId: string, deleteSeries: boolean) {
    let requestUrl = `/OutageCalendar/DeleteOutageWindow?outageWindowId=${outageWindowId}`
    if (deleteSeries) {
      requestUrl += `&isSeriesDelete=${deleteSeries}`
    }
    return this.delete(requestUrl)
  }

  getFlexibleRiskMatrix() {
    return this.get(`/RiskMatrix/GetFlexibleRiskMatrix`);
  }

  getRiskMatrixValues(assetIds, outageArea) {
    return this.post(`/RiskMatrix/GetAssetRiskForGrid`,
      {
        assetIds: [],
        OutageAreaIds: outageArea.map(outageArea => outageArea.id)
      }
    ).pipe(map(res => res.riskMatrixDtos));
  }

  getAllTasksForEvent(eventId: string): Observable<any> {
    return this.getTasksForCart({eventId:[eventId]}, '', { selectedGroups: [{}], excludedItems: [], includedItems: [] }, 'Event')
  }

  getAlltasksForOutageWindow(outageWindowId: string): Observable<any> {
    return this.getTasksForCart({outageWindowId:[outageWindowId]}, '', { selectedGroups: [{}], excludedItems: [], includedItems: [] }, 'Event')
  }

  closeEventManually(eventId: any) {
    return this.post(`/Events/CloseEventManually`, { eventId })
  }

  addTaskComment(taskId: string, comment: string, isRejectionComment = false, isCancellationComment = false): Observable<any> {
    return this.post('/TaskExecution/SaveTaskComment', {
      taskCommentsInfo: {
        commentsDesc: comment,
        taskId,
        taskStatusId: this.constService.emptyGuid, // this is only sent as backend api expects a field with this key,
        isRejectionComment,
        isCancellationComment
      }
    });
  }

  getExecuteDetails(taskId) {
    return this.get('/TaskExecution/GetExecuteTaskDetails?taskId='+taskId)
      .pipe(map(res => res.taskExecuteGetSaveDto))
      .pipe(tap(res => {
        res.taskComments.forEach(comment => {
          comment.createDateTime = moment.utc(comment.createDateTime).local().toDate();
        });

        res.taskActivityLogs.forEach(activity => {
          activity.createDate = moment.utc(activity.createDate).local().toDate();
        });

        res.taskComments.sort((a, b) => moment(b.commentDate).diff(moment(a.commentDate)));

        res.swmsTaskData?.forEach(swms => {
          swms?.recommendationGetDto?.forEach(recommendation => {
            recommendation.recommendedDate = moment.utc(recommendation.recommendedDate).local().toDate();
          });
        });
      }));
  }

  saveTaskBasedOnState(taskExecutionDTO, isOffline = false) {
    taskExecutionDTO = { ...taskExecutionDTO, isOffline, ...{ taskComments: taskExecutionDTO.taskComments?.filter(comment => comment.commentId) } }
    return this.post('/TaskExecution/SaveDataToStagingTables', taskExecutionDTO)
  }

  getProjectDetails() {
    return this.get('/OfflineExecution/GetOfflineProjectDetails')
  }

  updateExecuteTaskStatus(taskId, isRevoke, isReject) {
    return this.post(`/TaskExecution/UpdateExecuteTaskStatus?taskId=${taskId}&isRevoke=${isRevoke}&isReject=${isReject}`)
  }

  saveValidateTaskDetails(payload) {
    return this.post('/TaskExecution/SaveValidateTaskDetails', payload);
  }

  rejectTask(taskId){
    return this.post(`/TaskExecution/UpdateRejectTaskStatus?taskId=${taskId}`);
  }

  revokeTask(taskId) {
    return this.post(`/TaskExecution/UpdateRevokeTaskStatus?taskId=${taskId}`);
  }

  getAllUpdatesForBacklog() {
    return this.get(`/backlog/updates`);
  }

  getUpdatesForBacklogFilterMasterData() {
    return this.get(`/backlog/updates/filter-masterdata`);
  }

  acknowledgeBacklogUpdates() {
    return this.patch('/backlog/acknowledge-updates');
  }

  getEventDetailsForAnInstallation(installationId: string) {
    return this.get(`/Events/event-details?installationId=${installationId}`);
  }

  addToOfflineTasks(tasks: any[], isOffline: Boolean) {
    return this.post('/OfflineExecution/AddTaskToOfflineOnlineCart', {
      taskIds: tasks.map(task => task.id),
      IsOffline: isOffline
    });
  }

  getOfflineTasks() {
    return this.get(`/OfflineExecution/GetOfflineTaskDetails?isMyflow=true`);
  }

  setMPin(password: string): Observable<void> {
    return this.post('/OfflineExecution/SetPinForUser', { pin: password })
  }

  getMPin(): Observable<any> {
    return this.get('/OfflineExecution/GetPinForUser').pipe(tap(res => {
      if (res?.pin) { this.cryptoService.setPasswordInMemory(res.pin); }
    }));
  }

  getAllInstallations() {
    return this.get(`/IrregularTask/GetSubstationDictionaryItems`);
  }
  getAllEquipmentCategory(substationId) {
    return this.get(`/IrregularTask/GetEquipmentCategoryDictionaryItems?substationId=`+substationId);
  }
  getAllEquipment(equipmentCategoryId,substationId) {
    return this.get(`/IrregularTask/GetEquipmentDictionaryItems?equipmentCategoryId=`+equipmentCategoryId+`&substationId=`+substationId);
  }
  getAllSldRefNumber(equipmentId,substationId) {
    return this.get(`/IrregularTask/GetSldRefNumbersDictionaryItems?equipmentId=`+equipmentId+`&substationId=`+substationId);
  }
  getAllMaintPlanningActionTypes(equipmentId,planningTypeId,maintTypeId){
    return this.get('/IrregularTask/GetAllMaintPlanningActionTypes?equipmentId='+equipmentId+'&planningTypeId='+planningTypeId+'&maintTypeId='+maintTypeId);
  }
  saveIrregularTask(data) {
    return this.post('/IrregularTask/SaveIrregularTask',data);
  }


  getAllPoliciesAndDefaultDueDatesForAssets(assetIds: { assetId: string, isAsset: string }[],
      backlogData: { startDate: Date, endDate: Date,  criteria: string, backlogId: string, installations: any[] },
     ): Observable<{ [key: string]: any }> {
    if (!assetIds.length) { return of([]); }
    const payload = {
      selectedAssetsInfo: assetIds,
      backlogStartDate: moment(backlogData.startDate).format('YYYY-MM-DD'),
      backlogEndDate: moment(backlogData.endDate).format('YYYY-MM-DD'),
      isEdit: (backlogData.backlogId !== undefined) && (backlogData.backlogId !== null),
      backlogId : backlogData.backlogId,
      dueDateCriteria: backlogData.criteria,
      installationIds: backlogData.installations.map( (installation) => installation.id)
    }
    return this.post(
      `/backlog/get-policy-details-for-create-backlog`,
      payload
    ).pipe(map(res => {
      return res
    }));
  }

  getAllPlanningAndMaintenanceTypes(sldRef, eqptId, planningTypeId): Observable<any> {
    return this.get('/IrregularTask/GetAllMaintenanceTypes?equipmentId=' + sldRef + '&mAEquipmentId=' + eqptId + '&planningTypeId='+planningTypeId);
  }
  getIrregularTaskData(taskId){
    return this.get('/IrregularTask?taskId='+taskId);
  }

  /**check if no tasks are available in bulk actions window without multiple tabs */
  AreTasksInSingleBulkPayload(payload: { modifiedTasks: any[], copyToOtherTasks: any, otherTasks: any[] }) {
    return !(payload.modifiedTasks.length == 0)
  }

  /**check if no tasks are available in bulk actions window with multiple tabs */
  AreTasksInSplitBulkPayload(payload: { outageTasks: any, nonOutageTasks: any }) {
    return !(payload.outageTasks.modifiedTasks?.length == 0 && payload.outageTasks.otherTasks.length == 0 &&
      payload.nonOutageTasks.modifiedTasks?.length == 0 && payload.nonOutageTasks.otherTasks.length == 0)

  }
  bulkPlanTasks(payload: { outageTasks: any, nonOutageTasks:any }) {
    if (this.AreTasksInSplitBulkPayload(payload)) {
      return this.post('/BulkAction/Plan', payload);
    }
    else {
      return of();
    }
  }

  bulkCancelTasks(payload: {modifiedTasks: any[], copyToOtherTasks: any, otherTasks:any[]}){
    if (this.AreTasksInSingleBulkPayload(payload)) {
      return this.post('/BulkAction/Cancel', payload);
    }
    else {
      return of();
    }
  }

  bulkScheduleTasks(payload: { outageTasks: any, nonOutageTasks:any }){
    if (this.AreTasksInSplitBulkPayload(payload)) {
      return this.post('/BulkAction/Schedule', payload);
    }
    else {
      return of();
    }
  }

  bulkRejectTasks(payload: {modifiedTasks: any[], copyToOtherTasks: any, otherTasks:any[]}){
    if (this.AreTasksInSingleBulkPayload(payload)) {
      return this.post('/BulkAction/RejectTasks', payload);
    }
    else {
      return of();
    }
  }

  bulkRevokeTasks(payload: {modifiedTasks: any[], copyToOtherTasks: any, otherTasks:any[]}){
    if (this.AreTasksInSingleBulkPayload(payload)) {
      return this.post('/BulkAction/Revoke', payload);
    }
    else {
      return of();
    }
  }

  moveToBacklog(query: any) {
    let payload = {
      filtersData: query.appliedFilters,
      searchText: query.searchText,
      cart: {
        includedItems: query.includedItems.map( (item) => item.id ),
        excludedItems: query.excludedItems.map( (item) => item.id ),
        selectedGroups: query.selectedGroups
      },
      mmType: 'Event'
    }
    return this.post('/BulkAction/MoveToBacklog', payload)
  }

  getCommonSafetySteps() {
    return this.get('/BulkAction/GetCommonSwms').pipe(map((res: any) => res.swmsDto));
  }

  getBulkExecuteTaskDetails(taskIds: string[]) {
    return this.post('/BulkAction/GetBulkExecuteTaskDetails', { taskIds } );
  }

  submitBulkTaskExecution(tasksExecuteDetails: any[]) {
    // don't send comments that have no id, backend will add them again as new comments
    // these id less comments are UI added objects after save comment api is successful
    // (backend doesn't return the saved comment in that request)
    tasksExecuteDetails.forEach(executeDetails => {
      executeDetails.taskComments = executeDetails.taskComments?.filter(comment => comment.commentId)
    });
    return this.post('/BulkAction/BulkSaveDataToStagingTables', tasksExecuteDetails);
  }

  generateGroupReport(eventId: string) {
    return this.get('/Events/GenerateDownloadReportPdf?eventId='+eventId);
  }

  downloadGroupReport(reportId: string){
    return this.get('/Events/DownloadReportDocument?groupreportid='+reportId);
  }

  downloadConsolidatedReport(eventId: string){
    return this.get('/Events/DownloadGroupReport?eventId='+eventId);
  }

  //Task overdue criterias APIs
  getAllTaskStateList() {
    return this.get(`/TaskExecution/GetTaskCriteria`)
      .pipe(map(res => res.taskStateList));;
  }

  createTaskOverDue(payload: any) {
    return this.post(`/TaskExecution/UpdateTaskCriteria`, payload);
  }

  //To get task reports - Documentation tab
  getTasksReports(pageNumber: number, pageSize: number, search: string, sortOrder: string, sortcolumn: string) {
    let endPoint = `/TaskExecution/GetTaskReports`;
    let requestUrl = `?PageNumber=${pageNumber}&PageSize=${pageSize}&SearchText=${search}&SortOrder=${sortOrder}&SortColumn=${sortcolumn}`;
    return this.get(endPoint + requestUrl)
    .pipe(map(res => res.taskReportsDtoList));
  }

  getMaintanaceRecordsByAssetId(assetId){
    return this.get('/Events/GetMaintananceRecordsByAssetId?assetId=' + assetId);
  }
  
  //To validate duplicate name 
  validateName(name: string, mmType: string, id?: string) {
    let requestUrl = `/Common/duplicate-name-check?name=${name}&mmType=${mmType}`;
    if (id) {
      requestUrl += `&id=${id}`;
    }
    return this.get(requestUrl);
  }
}
